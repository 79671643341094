@use '@jds/core/dist/styles/dist-tokens/color/palette/palette' as palette;
@use 'sass:list';
@use 'sass:map';

@import '~@jds/core/dist/styles/main';
@include createDefaultTheme([ 'reliance', 'navi-midnight', 'sky' ]);

/* @font-face {
  font-family: 'JioType';
  src: local('JioType'),
    url('./fonts/JioType-Light.woff2') format('woff2');
  font-weight:300;
  font-style:normal;
  font-display:swap;
}

@font-face {
  font-family: 'JioType';
  src: local('JioType'),
    url('./fonts/JioType-Black.woff2') format('woff2');
  font-weight:900;
  font-style:normal;
  font-display:swap;
}

@font-face {
  font-family: 'JioType';
  src: local('JioType'),
    url('./fonts/JioType-LightItalic.woff2') format('woff2');
  font-weight:300;
  font-style:italic;
  font-display:swap;
}

@font-face {
  font-family: 'JioType';
  src: local('JioType'),
    url('./fonts/JioType-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'JioType';
  src: local('JioType'),
    url('./fonts/JioType-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'JioType';
  src: local('JioType'),
    url('./fonts/JioType-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
} */

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-primary-50);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
