.spinner {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.spinner-container {
  align-self: center;
}
